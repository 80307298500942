import React from "react";
import Developer_image from "../../../assests/image/undraw_programming_-2-svr.svg";
import Github_logo from "../../../assests/image/icons8-github.svg";
import Mail_logo from "../../../assests/image/desktop/favicon.ico";
import LinkdIn from "../../../assests/image/linkdin_Icon.png";
import location from "../../../assests/image/desktop/location.png";
// import Typewriter from "typewriter-effect";
// import { NavLink } from "react-router-dom";
const Welcome = () => {
  return (
    <section className="Welcome container scroll_obs">
      <div className="Welcome_Container">
        <div className="Welcome_Social">
          <a
            href="https://linkedin.com/in/vignesh-chari-vangala-301281141"
            target="_blank"
            rel="noreferrer"
            title="LinkdIn Vignesh Chari"
          >
            <img src={LinkdIn} alt="Linkdin" />
          </a>
          <a
            href="https://github.com/Vignesh960"
            target="_blank"
            rel="noreferrer"
            title="Github Vignesh Chari"
          >
            <img src={Github_logo} alt="github" />
          </a>
          <a
            href="mailto:vigneshvangala@gmail.com"
            target="_blank"
            rel="noreferrer"
            title="vigneshvangala@gmail.com"
          >
            <img src={Mail_logo} alt="mail me" />
          </a>
        </div>
        <div className="Welcome_Intro">
          <div className="Intro_Container">
            <p className="Welcome_Intro_Heading Title_Text">
              Hi,I'm Vignesh Chari
            </p>
            <div className="Welcome_Intro_Heading_Title_Text_Location_Information">
              <img className="Welcome_Intro_Heading_Title_Text_Location_Information_Icon" src={location} alt="location"/>
              <p className="Welcome_Intro_Heading Title_Text_Location_Information_Icon_Text">
              Hyderabad, India
            </p>
            
            </div>
            
            <p className="Welcome_Intro_SubHeading SubHeading_Text">
              Software Developer
              {/* <Typewriter options={{autoStart:true,loop:true,delay:50,strings:["Software Engineer","Software Developer","Software Developement Engineer"]}}/> */}
            </p>
            {/* <p className="Text">
              High level Experience in Web and Windows Apps Development
              knowledge,Delivering quality work towards Client requirements.Experience in various technologies like Python,C#.Net,ASP.NET Core,Django,Web UI Technologies,PostgreSQL/SQL.
            </p> */}
            <p className="Text">With a proven track record of delivering top-notch solutions tailored to client needs, I specialize in a diverse range of technologies including Python, C#.NET, ASP.NET Core, Django, Web UI Technologies, and PostgreSQL/SQL. I strive to push the boundaries of what’s possible, ensuring each project achieves outstanding results.</p>
            {/* // <NavLink to="contact" className="Contact_Button" title="email me">
            //   contact <span className="material-icons">contact_page</span>
            // </NavLink> */}
          </div>
        </div>
        <div className="Welcome_Image">
          <img src={Developer_image} alt="developer" />
        </div>
      </div>
    </section>
  );
};
export default Welcome;
